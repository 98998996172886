<template>
  <div class="left-sidebar">
    <div>
      <div class="rest-type accordion accordion-flush"  id="submenu">
        <div class="accordion-item">
            <div class="accordion-header">
              <div class="profile-container">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1">
                        <div><img :src="profile" alt="profile" /></div>
              <div class="res-detais">
                <p class="res fw-bold fs-14">Restaurant</p>
                <p class="res-name">Tasty Food Restaurant</p>
              </div>
                      </button>
                      
          </div>
            </div>
            <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#submenu">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12" v-for="(r, index) in restuarants" :key="index">
                            <div class="pb-3">
                              <router-link to="#" class="link"><img :src="r.image" class="img-avatar-sm" /><span class="fw-bold">{{ r.business_name }}</span></router-link>
                            </div>
                          </div>
                        </div>
                        <pre>{{ r }}</pre>
                        <router-link to="/create-restaurant" class="btn btn-ghost-accent"><i class="fa fa-plus"></i> Add new Restaurant</router-link>
                      </div>
                    </div>
        </div>
      </div>
      <div class="mt-4" id="styles">
        <div mat-list-item active-class="active-p" id="nav-menu" class="menu">
          <router-link
            to="/Vendor-dashboard"
            class="link-active"
            active-class="active-p"
          >
            <img :src="dashboard" alt="history" />
            Dashboard
          </router-link>
        </div>
        <div mat-list-item id="nav-menu" class="menu">
          <router-link
            to="/order"
            class="link-active"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="MyLogo" alt="payment" />
            Recent Orders
          </router-link>
        </div>

        <div id="nav-menu" class="menu">
          <router-link
            to="/make-payment"
            class="link-active"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="payment" alt="history" />
            Payment Method
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/menu"
            id="nav-anchor"
            class="link-active"
            active-class="active-p"
          >
            <img :src="menu" alt="restaurants" />
            Menu
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/vendor-rider"
            id="nav-anchor"
            class="link-active"
            active-class="active-p"
          >
            <img :src="bike" alt="vendor" />
            Riders
          </router-link>
        </div>

        <div id="nav-menu" class="menu">
          <router-link
            to="/vendor-review"
            class="link-active"
            id="nav-anchor"
            active-class="active-p"
          >
            <img :src="review" alt="profile" />
            Reviews
          </router-link>
        </div>
        <div id="nav-menu" class="menu">
          <router-link
            to="/edit-business"
            id="nav-anchor"
            class="link-active"
            active-class="active-p"
          >
            <img :src="Edit" alt="profile" />
            Edit Business
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Sidebar",
  data() {
    return {
      MyLogo: require("../../../assets/colored_order.png"),
      payment: require("../../../assets/payment.png"),
      menu: require("../../../assets/Menu.png"),
      dashboard: require("../../../assets/dashboard.png"),
      bike: require("../../../assets/bike.png"),
      Edit: require("../../../assets/edit.png"),
      review: require("../../../assets/review.png"),
      profile: require("../../../assets/profile_15.png"),
    };
  },

  computed: {
    ...mapGetters({
      restuarants: "vendor/vendor_info"
    })
  }
};
</script>

<style scoped>
.menu img {
  padding-right: 20px;
}

.res {
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(133, 77, 39, 1);
}

.res-detais {
  padding-left: 5px;
}

.res-name {
  font-weight: 600;
  font-size: 12px;
}

.profile-container {
  display: flex;
  padding: 10px;
}

.rest-type {
  margin-top: 12%;
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.left-sidebar h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 15%;
  font-weight: 600;
}

.active-p {
  background-color: rgba(240, 239, 245, 1);
  border-radius: 4px;
  color: white;
}

#nav-anchor {
  text-decoration: none;
  font-size: 14px;
}

#nav-menu {
  padding-top: 13px;
  padding-bottom: 13px;
}

.wrapper i {
  color: #fff;
}

.link {
  text-decoration: none;
  font-size: 14px;
  padding: 4% 5%;
  color: rgba(67, 66, 76, 1)
}

.link-active {
  text-decoration: none;
  font-size: 14px;
  padding: 4% 5%;
  color: rgba(133, 77, 39, 1);
}

#styles a {
  color: rgba(67, 66, 76, 1);
}

.is-active {
  background-color: rgb(174, 171, 171);
}
.accordion-button:focus {
  background-color: rgba(133, 77, 39, 0.1);
  border: none;
  outline: none;
}
</style>
